import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPlay,
    faPause,
    faStepForward,
    faVolumeUp,
    faVolumeMute,
} from '@fortawesome/free-solid-svg-icons'

interface Data {
    image: string
    title: string
    group: string
    duration: number
    timer: number
    volume?: number
    mute?: 'mute' | 'unmute'
    isPlaying: boolean
    actionTimer?: number
    setActionPlay: Dispatch<SetStateAction<boolean | undefined>>
    setActionPause: Dispatch<SetStateAction<boolean | undefined>>
    setActionNext: Dispatch<SetStateAction<boolean>>
    setActionVolume: Dispatch<SetStateAction<number | undefined>>
    setActionMute: Dispatch<SetStateAction<'mute' | 'unmute' | undefined>>
    setActionTimer?: Dispatch<SetStateAction<number | undefined>>
}
const Player2: FC<Data> = ({
    image,
    title,
    group,
    duration,
    timer,
    isPlaying,
    volume,
    mute,
    actionTimer,
    setActionPlay,
    setActionPause,
    setActionNext,
    setActionVolume,
    setActionMute,
    setActionTimer,
}) => {
    const [valueTimer, setValueTimer] = useState((timer * 100) / duration)

    const onActionPlayer = () => {
        if (isPlaying) {
            setActionPlay(false)
            setActionPause(true)
        } else {
            setActionPlay(true)
            setActionPause(false)
        }
    }

    const onVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
        setActionVolume(Number(e.target.value))
        setActionMute('unmute')
    }

    const onTimer = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        if (setActionTimer) {
            setActionTimer((Number(e.currentTarget.value) * duration) / 100)
        }
    }

    useEffect(() => {
        if (actionTimer === undefined) {
            setValueTimer((timer * 100) / duration)
        }
    }, [timer])

    return (
        <div className='bg-secondary w-full h-[100px]'>
            <div className='absolute w-full bottom-[93px]'>
                <input
                    type='range'
                    className='w-full input-range-timer accent-primary bg-neutral-500'
                    style={{ backgroundSize: `${valueTimer}% 100%` }}
                    value={valueTimer}
                    onChange={(e) => setValueTimer(Number(e.target.value))}
                    onMouseUp={onTimer}
                />
            </div>
            <div className='grid grid-cols-3 h-[94px] px-5'>
                <div className='flex items-center'>
                    <img className='mr-4' src={image} alt='imageSong' width='60' />
                    <div className='leading-tight'>
                        <p className='text-primary text-lg'>{title}</p>
                        <p className='text-white text-base'>{group}</p>
                    </div>
                </div>
                <div className='flex justify-center items-center'>
                    {/* <FontAwesomeIcon
                        className='text-3xl text-primary/[.9] hover:text-primary pr-8'
                        icon={faStepBackward}
                    /> */}
                    <button
                        className='relative grid content-center h-10 w-10 bg-primary/[.9] rounded-full hover:bg-primary'
                        onClick={() => onActionPlayer()}
                    >
                        <FontAwesomeIcon
                            className='text-xl absolute right-0 left-0 top-0 bottom-0 m-auto text-white'
                            icon={isPlaying ? faPlay : faPause}
                        />
                    </button>
                    <FontAwesomeIcon
                        className='text-3xl text-primary/[.9] hover:text-primary pl-8'
                        icon={faStepForward}
                        onClick={() => setActionNext(true)}
                    />
                </div>
                <div className='flex justify-end items-center'>
                    <button
                        className='self-center'
                        onClick={() => setActionMute(mute === 'mute' ? 'unmute' : 'mute')}
                    >
                        <FontAwesomeIcon
                            className='text-lg text-white pl-8 mr-3'
                            icon={mute === 'mute' ? faVolumeMute : faVolumeUp}
                        />
                    </button>
                    <input
                        type='range'
                        className='accent-white hover:accent-primary [&::-webkit-slider-thumb]:bg-neutral-500'
                        value={volume ?? 100}
                        onChange={onVolume}
                    />
                    <p className='text-white ml-8'>
                        {moment.utc(timer * 1000).format('mm:ss')} /{' '}
                        {moment.utc(duration * 1000).format('mm:ss')}{' '}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Player2
