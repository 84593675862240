import { FC, useEffect, useRef, useState, DragEvent } from 'react'
import {
    Song,
    deleteSongPlaylist,
    deleteSongQueue,
    moveSongPlaylist,
} from '../../../../services/playerApi'

import ItemPlaylist from '../../../../components/itemPlaylist'
import ServerSocket from '../../../../socket'
import Player2 from '../../../../components/player2'
import Form from '../../../../components/addSong/form'
import Spinner from '../../../../components/spinner'
import Login from './login'

const Player: FC = () => {
    const [isLoginSuccessful, setIsLoginSuccessful] = useState(false)
    const [isReady, setIsReady] = useState(false)
    const [nextSong, setNextSong] = useState(false)
    const [timer, setTimer] = useState(0)
    const [queue, setQueue] = useState<Song[]>([])
    const [playlist, setPlaylist] = useState<Song[]>([])
    const [playing, setPlaying] = useState<Song | undefined>(undefined)
    const [isPlaying, setIsPlaying] = useState(false)
    const [clientId, setClientId] = useState('')
    const [actionPlay, setActionPlay] = useState<boolean | undefined>(false)
    const [actionPause, setActionPause] = useState<boolean | undefined>(false)
    const [actionNext, setActionNext] = useState(false)
    const [actionVolume, setActionVolume] = useState<number | undefined>(undefined)
    const [actionMute, setActionMute] = useState<'mute' | 'unmute' | undefined>()
    const [actionDeletePlaylist, setActionDeletePlaylist] = useState<boolean | number>(false)
    const [actionDeleteQueue, setActionDeleteQueue] = useState<boolean | number>(false)
    const [actionTimer, setActionTimer] = useState<number | undefined>()
    const [updateQueue, setUpdateQueue] = useState<{ song: Song; priority: boolean }>()
    const [actionMovePlaylist, setActionMovePlaylist] = useState<
        { from: number; to: number } | undefined
    >()

    const [showModal, setShowModal] = useState(false)
    const [showModalError, setShowModalError] = useState(false)
    const [showModalSuccess, setShowModalSuccess] = useState(false)
    const [error, setError] = useState('')
    const [positionQueue, setPositionQueue] = useState<number>()

    useEffect(() => {
        const isLogin = localStorage.getItem('successLogin')
        if (isLogin) {
            setIsLoginSuccessful(true)
        }
    }, [])

    useEffect(() => {
        if (nextSong) {
            if (queue.length > 0) {
                const newPlaying = JSON.parse(JSON.stringify(queue[0]))
                setPlaying(newPlaying)

                const newQueue = JSON.parse(JSON.stringify(queue))
                newQueue.shift()
                setQueue(newQueue)
            } else {
                const newPlaying = JSON.parse(JSON.stringify(playlist[0]))
                setPlaying(newPlaying)

                const newPlaylist = JSON.parse(JSON.stringify(playlist))
                newPlaylist.shift()
                setPlaylist([...newPlaylist, newPlaying])
            }
            setNextSong(false)
            setActionNext(false)
            setActionPlay(true)
            setActionPause(false)
        }
    }, [nextSong])

    const actionUpdateQueue = (song: Song, priority = false) => {
        if (priority) {
            setQueue([song, ...queue])
        } else {
            setQueue([...queue, song])
        }
        setUpdateQueue({ song, priority })
    }

    const actionDeleteSongQueue = async (index: number) => {
        const { result } = await deleteSongQueue(index + 1)
        if (result) {
            const queueAux = queue
            queueAux.splice(index, 1)

            setQueue(queueAux)
            setActionDeleteQueue(index + 1)
        }
    }

    const actionDeleteSongPlaylist = async (index: number) => {
        const { result } = await deleteSongPlaylist(index + 1)
        if (result) {
            const playlistAux = playlist
            playlistAux.splice(index, 1)

            setPlaylist(playlistAux)
            setActionDeletePlaylist(index + 1)
        }
    }

    const dragItem = useRef<number | null>(null)
    const dragOverItem = useRef<number | null>(null)
    const dragStart = (e: DragEvent<HTMLDivElement>, position: number) => {
        dragItem.current = position
    }
    const dragEnter = (e: DragEvent<HTMLDivElement>, position: number) => {
        dragOverItem.current = position
    }
    const drop = async () => {
        if (dragItem.current !== null && dragOverItem.current !== null) {
            const copyListItems = [...playlist]
            const dragItemContent = copyListItems[dragItem.current]
            copyListItems.splice(dragItem.current, 1)
            copyListItems.splice(dragOverItem.current, 0, dragItemContent)

            const [from, to] = [dragItem.current, dragOverItem.current]
            dragItem.current = null
            dragOverItem.current = null

            setPlaylist(copyListItems)
            await moveSongPlaylist(from, to)
            setActionMovePlaylist({ from, to })
        }
    }
    return (
        <>
            <ServerSocket
                playlist={playlist}
                queue={queue}
                isPlaying={isPlaying}
                isReady={isReady}
                actionPlay={actionPlay}
                actionPause={actionPause}
                actionNext={actionNext}
                actionVolume={actionVolume}
                actionMute={actionMute}
                updateQueue={updateQueue}
                actionMovePlaylist={actionMovePlaylist}
                actionDeletePlaylist={actionDeletePlaylist}
                actionDeleteQueue={actionDeleteQueue}
                actionTimer={actionTimer}
                setClientId={setClientId}
                setIsPlaying={setIsPlaying}
                setIsReady={setIsReady}
                setNextSong={setNextSong}
                setPlaying={setPlaying}
                setPlaylist={setPlaylist}
                setQueue={setQueue}
                setTimer={setTimer}
                setActionPlay={setActionPlay}
                setActionPause={setActionPause}
                setActionVolume={setActionVolume}
                setActionMute={setActionMute}
                setActionMovePlaylist={setActionMovePlaylist}
                setActionDeletePlaylist={setActionDeletePlaylist}
                setActionDeleteQueue={setActionDeleteQueue}
                setActionTimer={setActionTimer}
            />
            {isLoginSuccessful ? (
                isReady ? (
                    playlist.length > 0 ? (
                        <>
                            <div className='flex h-full' style={{ height: 'calc(100% - 100px)' }}>
                                <div className='w-[35%] border-r border-[#A7A7A7] pt-8 px-4 pb-4'>
                                    <h1 className='text-center text-black'>LISTA DE CANCIONES</h1>
                                    <div
                                        className='flex flex-col gap-4 mx-3 mt-3 overflow-y-auto'
                                        style={{ height: 'calc(100% - 44px)' }}
                                    >
                                        {playlist.map((song, index) => (
                                            <div
                                                className='cursor-move'
                                                onDragStart={(e) => dragStart(e, index)}
                                                onDragEnter={(e) => dragEnter(e, index)}
                                                onDragOver={(e) =>
                                                    e.currentTarget.classList.add(
                                                        'listItemDraggableOver',
                                                    )
                                                }
                                                onDragLeave={(e) =>
                                                    e.currentTarget.classList.remove(
                                                        'listItemDraggableOver',
                                                    )
                                                }
                                                onDragEnd={drop}
                                                key={index}
                                                draggable
                                            >
                                                <ItemPlaylist
                                                    index={index}
                                                    title={song.title}
                                                    group={song.group}
                                                    year={song.year}
                                                    duration={song.duration}
                                                    reproductions={song.reproductions}
                                                    album={JSON.parse(song.album ?? '{}')}
                                                    key={`ItemPlaylist${index}`}
                                                    tags={JSON.parse(song.tags ?? '{}')}
                                                    className='basis-full'
                                                    isAdmin={true}
                                                    actionDeleteSong={actionDeleteSongPlaylist}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className='w-[35%] border-r border-[#A7A7A7] pt-8 px-4 pb-4'>
                                    <h1 className='text-center text-black'>LISTA DE PEDIDOS</h1>
                                    <div
                                        className='mx-3 mt-3 overflow-y-auto'
                                        style={{ height: 'calc(100% - 44px)' }}
                                    >
                                        {queue.length > 0 ? (
                                            queue.map((song, index) => (
                                                <ItemPlaylist
                                                    index={index}
                                                    title={song.title}
                                                    group={song.group}
                                                    year={song.year}
                                                    duration={song.duration}
                                                    reproductions={song.reproductions}
                                                    album={JSON.parse(song.album ?? '{}')}
                                                    key={`ItemPlaylist${index}`}
                                                    tags={JSON.parse(song.tags ?? '{}')}
                                                    request={
                                                        song.clientId
                                                            ? song.clientId === clientId
                                                            : false
                                                    }
                                                    className='mt-4'
                                                    isAdmin={true}
                                                    actionDeleteSong={actionDeleteSongQueue}
                                                />
                                            ))
                                        ) : (
                                            <h1 className='text-center'>
                                                No hay pedidos pendientes
                                            </h1>
                                        )}
                                    </div>
                                </div>
                                <div className='w-[30%] bg-white pt-8 px-4 pb-4'>
                                    <h1 className='text-center text-black'>BUSCAR</h1>
                                    <Form
                                        setShowModal={setShowModal}
                                        setShowModalSuccess={setShowModalSuccess}
                                        setShowModalError={setShowModalError}
                                        addSongQueue={actionUpdateQueue}
                                        clientId={clientId}
                                        setError={setError}
                                        setPositionQueue={setPositionQueue}
                                        isAdmin={true}
                                    />
                                </div>
                            </div>
                            <Player2
                                image={playing ? playing.image : ''}
                                title={playing ? playing.title : ''}
                                group={playing ? playing.group : ''}
                                duration={playing && playing.duration ? playing.duration : 0}
                                timer={timer}
                                volume={actionVolume}
                                mute={actionMute}
                                isPlaying={isPlaying}
                                actionTimer={actionTimer}
                                setActionPause={setActionPause}
                                setActionPlay={setActionPlay}
                                setActionNext={setActionNext}
                                setActionVolume={setActionVolume}
                                setActionMute={setActionMute}
                                setActionTimer={setActionTimer}
                            />
                        </>
                    ) : (
                        <div
                            style={{
                                textAlign: 'center',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <Spinner className='w-8 h-8 mx-auto' />
                            Cargando ...
                        </div>
                    )
                ) : (
                    <div className='grid h-full content-center'>
                        <h1 className='text-center'>El reproductor no esta disponible</h1>
                    </div>
                )
            ) : (
                <Login setIsLoginSuccessful={setIsLoginSuccessful} />
            )}
        </>
    )
}

export default Player
