import { Dispatch, FC, SetStateAction, useState } from 'react'

interface Data {
    setIsLoginSuccessful: Dispatch<SetStateAction<boolean>>
}
const Login: FC<Data> = ({ setIsLoginSuccessful }) => {
    const [password, setPassword] = useState('')
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')

    const handleSubmit = () => {
        setBtnDisabled(true)
        if (password === 'cabeerna2024') {
            localStorage.setItem('successLogin', 'true')
            setSuccess('Contraseña correcta')
            setError('')
            setBtnDisabled(false)
            setIsLoginSuccessful(true)
        } else {
            setError('Contraseña incorrecta')
            setSuccess('')
            setBtnDisabled(false)
        }
    }

    return (
        <div className='grid h-full content-center'>
            <div className='flex m-auto'>
                <h1>Escribe la contraseña para continuar: </h1>
                <input
                    className='w-40 ml-2'
                    type='password'
                    name='password'
                    id='password'
                    value={password}
                    onInput={(e) => {
                        setPassword(e.currentTarget.value)
                        setBtnDisabled(e.currentTarget.value === '')
                    }}
                />
                <button
                    disabled={btnDisabled}
                    className='ml-2 bg-secondary text-white px-6 rounded hover:bg-primary hover:text-secondary hover:shadow disabled:bg-[#E5E5E5] disabled:text-[#ABABAB] disabled:shadow-inherit'
                    onClick={handleSubmit}
                >
                    Entrar
                </button>
            </div>
            <p className={`text-center text-red-500`}>{error}</p>
            <p className={`text-center text-green-500`}>{success}</p>
        </div>
    )
}

export default Login
