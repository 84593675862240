import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheckCircle,
    faPlay,
    faPause,
    faVolumeUp,
    faVolumeMute,
    faStepForward,
} from '@fortawesome/free-solid-svg-icons'

interface Data {
    image: string
    title: string
    group: string
    duration: number
    volume?: number
    timer: number
    actionTimer?: number
    isPlaying: boolean
    request?: boolean
    isAdmin?: boolean
    setActionVolume?: Dispatch<SetStateAction<number | undefined>>
    setActionTimer?: Dispatch<SetStateAction<number | undefined>>
    setActionPlay?: Dispatch<SetStateAction<boolean | undefined>>
    setActionPause?: Dispatch<SetStateAction<boolean | undefined>>
    setActionNext?: Dispatch<SetStateAction<boolean>>
}

const Player: FC<Data> = ({
    image,
    title,
    group,
    duration,
    timer,
    actionTimer,
    volume = 0,
    isPlaying,
    request = false,
    isAdmin,
    setActionVolume,
    setActionPlay,
    setActionPause,
    setActionNext,
    setActionTimer,
}) => {
    const [showVolume, setShowVolume] = useState(false)
    const [valueTimer, setValueTimer] = useState((timer * 100) / duration)

    const onActionPlayer = () => {
        if (setActionPlay && setActionPause) {
            if (isPlaying) {
                setActionPlay(false)
                setActionPause(true)
            } else {
                setActionPlay(true)
                setActionPause(false)
            }
        }
    }

    const onVolume = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (setActionVolume) {
            setActionVolume(Number(e.target.value))
        }
    }

    const onTimer = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        if (setActionTimer) {
            setActionTimer((Number(e.currentTarget.value) * duration) / 100)
        }
    }

    useEffect(() => {
        if (actionTimer === undefined) {
            setValueTimer((timer * 100) / duration)
        }
    }, [timer])

    return (
        <div className='bg-[#1d1d1b] text-white mt-[41px]'>
            <div className='absolute w-full bottom-[54px]'>
                <div className='text-black flex justify-between px-3 text-xs'>
                    <p>{moment.utc(timer * 1000).format('mm:ss')}</p>
                    <p>{moment.utc(duration * 1000).format('mm:ss')}</p>
                </div>
                <input
                    type='range'
                    className='w-full input-range-timer accent-primary bg-neutral-500'
                    style={{ backgroundSize: `${valueTimer}% 100%` }}
                    value={valueTimer}
                    onChange={(e) => (isAdmin ? setValueTimer(Number(e.target.value)) : undefined)}
                    onMouseUp={isAdmin ? onTimer : undefined}
                />
            </div>
            <div className='flex justify-between items-center'>
                <div className='flex items-center h-[60px] px-2'>
                    {isAdmin ? (
                        <></>
                    ) : (
                        <div className='relative w-[60px] h-[60px] grid content-center mr-4'>
                            <FontAwesomeIcon
                                className='text-3xl absolute right-0 left-0 top-0 bottom-0 m-auto text-white/[.7]'
                                icon={isPlaying ? faPlay : faPause}
                            />
                            <img src={image} alt='albumItem' width='60' />
                        </div>
                    )}
                    <div className='leading-tight'>
                        <p className='text-primary'>{title}</p>
                        <p>{group}</p>
                    </div>
                </div>
                <div>
                    {request && !isAdmin ? (
                        <FontAwesomeIcon className='text-3xl mr-2' icon={faCheckCircle} />
                    ) : (
                        <></>
                    )}
                </div>
                {isAdmin ? (
                    <div className='relative flex pr-3'>
                        <button
                            className='relative grid content-center h-10 w-10 bg-primary/[.9] rounded-full hover:bg-primary'
                            onClick={() => onActionPlayer()}
                        >
                            <FontAwesomeIcon
                                className='text-xl absolute right-0 left-0 top-0 bottom-0 m-auto text-white'
                                icon={isPlaying ? faPlay : faPause}
                            />
                        </button>
                        <FontAwesomeIcon
                            className='self-center text-3xl text-primary/[.9] hover:text-primary pl-3'
                            icon={faStepForward}
                            onClick={() => (setActionNext ? setActionNext(true) : null)}
                        />
                        <button
                            className='self-center pl-3'
                            onClick={() => setShowVolume(!showVolume)}
                        >
                            <FontAwesomeIcon
                                className='text-lg text-white'
                                icon={volume === 0 ? faVolumeMute : faVolumeUp}
                            />
                        </button>
                        <div
                            className={`${
                                showVolume ? '' : 'hidden'
                            } absolute bottom-[100px] left-[30px] h-7 py-1 pr-2 rounded bg-[#1d1d1b] shadow`}
                            style={{ transform: 'rotate(270deg)' }}
                        >
                            <input
                                type='range'
                                className='w-32 accent-white hover:accent-primary [&::-webkit-slider-thumb]:bg-neutral-500'
                                value={volume ?? 100}
                                onChange={onVolume}
                            />
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </div>
    )
}

export default Player
