import { FC, ReactElement, Dispatch, SetStateAction } from 'react'

interface Data {
    content: ReactElement
    show: boolean
    setShow: Dispatch<SetStateAction<boolean>>
}
const Modal: FC<Data> = ({ content, show, setShow }) => {

    return (
        <div
            className={`${
                show ? 'absolute' : 'hidden'
            } grid grid-cols-1 min-w-full min-h-screen top-0 left-0 bg-black bg-opacity-75 z-10`}
        >
            <div className='bg-white h-fit rounded m-3 mt-10 p-3 text-right '>
                <button className='cursor-pointer text-lg' onClick={() => setShow(false)}>
                    <span>x</span>
                </button>
                <div className='text-left'>{content}</div>
            </div>
        </div>
    )
}

export default Modal
