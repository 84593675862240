import { useRoutes, RouteObject } from 'react-router-dom'

import { MenuPage, MusicPage, AccountPage, PartnerPage } from '../views/pages/client'
import { PlayerPage } from '../views/pages/admin'

import LayoutClient from '../layout/client'
import LayoutAdmin from '../layout/admin'

const routesClient: RouteObject = {
    path: '/',
    element: <LayoutClient />,
    children: [
        {
            path: '/menu',
            element: <MenuPage />,
        },
        {
            path: '/musica',
            element: <MusicPage />,
        },
        {
            path: '/admin/musica',
            element: <MusicPage isAdmin={true} />,
        },
        {
            path: '/cuenta',
            element: <AccountPage />,
        },
        {
            path: '/socios',
            element: <PartnerPage />,
        },
    ],
}

const routesAdmin: RouteObject = {
    path: '/',
    element: <LayoutAdmin />,
    children: [
        {
            path: '/admin/player',
            element: <PlayerPage />,
        },
    ],
}

const MainRoutes = () => {
    return useRoutes([routesClient, routesAdmin])
}

export default MainRoutes
