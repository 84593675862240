import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import Navigation from './navigation'

import logo from '../../assets/images/logo.png'

const MainLayout = () => {
    const [status, setStatus] = useState(navigator.onLine)
    const [keyGlobal, setKeyGlobal] = useState(0)

    const setOnline = () => setStatus(true)
    const setOffline = () => setStatus(false)

    useEffect(() => {
        window.addEventListener('online', setOnline)
        window.addEventListener('offline', setOffline)

        return () => {
            window.removeEventListener('online', setOnline)
            window.removeEventListener('offline', setOffline)
        }
    }, [])

    useEffect(() => {
        setKeyGlobal(keyGlobal + 1)
    }, [status])

    return (
        <>
            <div className='h-screen font-display'>
                <div className='h-[100dvh] bg-[#F0F0F0]'>
                    <img className='m-auto pt-3' src={logo} alt='Cabeerna' width='100' />
                    <div style={{ height: 'calc(100% - 82px)' }}>
                        <Outlet key={keyGlobal} />
                    </div>
                </div>
                <Navigation />
            </div>
        </>
    )
}

export default MainLayout
