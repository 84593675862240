import { configureStore } from '@reduxjs/toolkit'

import { varsReducer } from './slices'
import { VarsState } from './slices/vars'

export interface Reducers {
    vars: VarsState
}
export const store = configureStore({
    reducer: {
        vars: varsReducer,
    },
})

export type AppDispatch = typeof store.dispatch
