import { createSlice } from '@reduxjs/toolkit'

export interface VarsState {
    isHiddenModal: boolean
}
const initialState: VarsState = {
    isHiddenModal: true,
}

export const varsSlice = createSlice({
    name: 'vars',
    initialState,
    reducers: {
        setIsHiddenModal: (state, action) => {
            state.isHiddenModal = action.payload
        },
    },
})

const { reducer, actions } = varsSlice

export const { setIsHiddenModal } = actions
export default reducer
