import Routes from './routes'

const App = () => {
    return (
        <div className='App'>
            <Routes />
        </div>
    )
}

export default App
