import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { io } from 'socket.io-client'

import { Song, getPlaylist } from './services/playerApi'
import { getClientId } from './common'
import Disconnect from './components/disconnect'

const { REACT_APP_DEV_ENDPOINT_SOCKET } = process.env

const socket = io(REACT_APP_DEV_ENDPOINT_SOCKET ?? '', {
    autoConnect: false,
    transports: ['websocket'],
})

interface Data {
    queue: Song[]
    playlist: Song[]
    isReady: boolean
    isPlaying: boolean
    actionPlay?: boolean
    actionPause?: boolean
    actionNext?: boolean
    actionVolume?: number
    actionTimer?: number
    actionMute?: 'mute' | 'unmute'
    actionDeleteQueue?: boolean | number
    actionDeletePlaylist?: boolean | number
    actionMovePlaylist?: { from: number; to: number } | undefined
    updateQueue?: { song: Song; priority: boolean }
    setIsPlaying: Dispatch<SetStateAction<boolean>>
    setIsReady: Dispatch<SetStateAction<boolean>>
    setNextSong: Dispatch<SetStateAction<boolean>>
    setTimer: Dispatch<SetStateAction<number>>
    setPlaylist: Dispatch<SetStateAction<Song[]>>
    setQueue: Dispatch<SetStateAction<Song[]>>
    setPlaying: Dispatch<SetStateAction<Song | undefined>>
    setClientId: Dispatch<SetStateAction<string>>
    setActionPlay?: Dispatch<SetStateAction<boolean | undefined>>
    setActionPause?: Dispatch<SetStateAction<boolean | undefined>>
    setActionVolume?: Dispatch<SetStateAction<number | undefined>>
    setActionTimer?: Dispatch<SetStateAction<number | undefined>>
    setActionMute?: Dispatch<SetStateAction<'mute' | 'unmute' | undefined>>
    setActionDeletePlaylist?: Dispatch<SetStateAction<boolean | number>>
    setActionDeleteQueue?: Dispatch<SetStateAction<boolean | number>>
    setActionMovePlaylist?: Dispatch<SetStateAction<{ from: number; to: number } | undefined>>
}
const ServerSocket: FC<Data> = ({
    playlist,
    queue,
    isReady,
    isPlaying,
    actionPlay,
    actionPause,
    actionNext,
    actionVolume,
    actionMute,
    actionTimer,
    actionDeleteQueue,
    actionDeletePlaylist,
    actionMovePlaylist,
    updateQueue,
    setIsReady,
    setNextSong,
    setTimer,
    setIsPlaying,
    setPlaylist,
    setQueue,
    setPlaying,
    setClientId,
    setActionPlay,
    setActionPause,
    setActionVolume,
    setActionMute,
    setActionTimer,
    setActionDeletePlaylist,
    setActionDeleteQueue,
    setActionMovePlaylist,
}) => {
    const [isDisconnect, setIsDisconnect] = useState(1) // 1: inicio, 2: normal, 3: desconexion, 4: cargando
    const [isConnected, setIsConnected] = useState(socket.connected)
    const [queueNewSong, setQueueNewSong] = useState<{ song: Song; priority?: boolean }>()
    const [queueDeleteSong, setQueueDeleteSong] = useState<number | boolean>(false)
    const [playlistDeleteSong, setPlaylistDeleteSong] = useState<number | boolean>(false)
    const [playlistMoveSong, setPlaylistMoveSong] = useState<
        { from: number; to: number } | undefined
    >()
    const [eventPlayPause, setEventPlayPause] = useState(true)
    const [eventVolume, setEventVolume] = useState<number | undefined>(undefined)
    const [eventTimer, setEventTimer] = useState<number>(0)

    useEffect(() => {
        if (isReady) {
            loadPlaylist()
            setIsPlaying(true)
        }
    }, [isReady])

    useEffect(() => {
        if (!isConnected) {
            socket.connect()
            socket.on('connect', () => {
                setIsConnected(socket.connected)
                setIsDisconnect(4)
            })
            socket.on('disconnect', () => {
                setIsDisconnect(3)
            })
            loadClientId()
        }

        // window.addEventListener('focus', onFocus)
        // window.addEventListener('blur', onBlur)
        // window.addEventListener('visibilitychange', onVisibilitychange)
    }, [])

    useEffect(() => {
        if (isDisconnect === 2) {
            socket.emit('getVolume')
            socket.emit('getMute')
        } else if (isDisconnect === 4) {
            loadPlaylist()
        }
    }, [isDisconnect])

    useEffect(() => {
        if (isConnected) {
            const isAvailable = (available: boolean) => {
                setIsReady(available)
            }

            const next = () => {
                setNextSong(true)
            }

            const currentTime = (time: number) => {
                setEventTimer(time)
            }

            const eventPlay = () => {
                setEventPlayPause(true)
            }

            const eventPause = () => {
                console.log('eventPause')
                setEventPlayPause(false)
            }

            const eventVolume = (volume: number) => {
                setEventVolume(volume)
            }

            const eventDeletePlaylist = (index: number) => {
                setPlaylistDeleteSong(index)
            }

            const eventMovePlaylist = (data: { from: number; to: number }) => {
                setPlaylistMoveSong(data)
            }

            const eventDeleteQueue = (index: number) => {
                setQueueDeleteSong(index)
            }

            const setCurrentTime = (time: number) => {
                setTimer(time)
            }

            const setVolume = (volume: number) => {
                if (setActionVolume) {
                    setActionVolume(volume)
                }
            }

            const setMute = (mute: 'mute' | 'unmute') => {
                if (setActionMute) {
                    setActionMute(mute)
                }
            }

            const queueSong = (data: { song: Song; priority?: boolean }) => {
                setQueueNewSong(data)
            }

            socket.on('available', isAvailable)
            socket.on('next', next)
            socket.on('currentTime', currentTime)
            socket.on('setCurrentTime', setCurrentTime)
            socket.on('setVolume', setVolume)
            socket.on('setMute', setMute)
            socket.on('queueSong', queueSong)
            socket.on('eventPlay', eventPlay)
            socket.on('eventPause', eventPause)
            socket.on('eventVolume', eventVolume)
            socket.on('eventDeletePlaylist', eventDeletePlaylist)
            socket.on('eventMovePlaylist', eventMovePlaylist)
            socket.on('eventDeleteQueue', eventDeleteQueue)
            socket.emit('isAvailable')
        }
    }, [isConnected])

    useEffect(() => {
        if (actionPlay) {
            setIsPlaying(true)
            socket.emit('actionPlay')
        }
    }, [actionPlay])

    useEffect(() => {
        if (actionPause) {
            setIsPlaying(false)
            socket.emit('actionPause')
        }
    }, [actionPause])

    useEffect(() => {
        if (actionNext) {
            socket.emit('actionNext')
        }
    }, [actionNext])

    useEffect(() => {
        if (actionVolume && eventVolume === undefined) {
            socket.emit('actionVolume', actionVolume)
        } else {
            setEventVolume(undefined)
        }
    }, [actionVolume])

    useEffect(() => {
        if (actionTimer && setActionTimer) {
            socket.emit('actionTimer', actionTimer)
        }
    }, [actionTimer])

    useEffect(() => {
        if (actionMute) {
            if (actionMute === 'mute') {
                socket.emit('actionMute', true)
            } else if (actionMute === 'unmute') {
                socket.emit('actionMute', false)
            }
        }
    }, [actionMute])

    useEffect(() => {
        if (actionDeleteQueue) {
            socket.emit('actionDeleteQueue', actionDeleteQueue)
            if (setActionDeleteQueue) setActionDeleteQueue(false)
        }
    }, [actionDeleteQueue])

    useEffect(() => {
        if (actionDeletePlaylist) {
            socket.emit('actionDeletePlaylist', actionDeletePlaylist)
            if (setActionDeletePlaylist) setActionDeletePlaylist(false)
        }
    }, [actionDeletePlaylist])

    useEffect(() => {
        if (actionMovePlaylist) {
            socket.emit('actionMovePlaylist', actionMovePlaylist)
            if (setActionMovePlaylist) setActionMovePlaylist(undefined)
        }
    }, [actionMovePlaylist])

    useEffect(() => {
        socket.emit('getCurrentTime')
        socket.emit('getVolume')
        socket.emit('getMute')
    }, [isPlaying])

    useEffect(() => {
        if (updateQueue) {
            socket.emit('updateQueue', updateQueue)
        }
    }, [updateQueue])

    useEffect(() => {
        if (queueNewSong) {
            if (queueNewSong.priority) {
                setQueue([queueNewSong.song, ...queue])
            } else {
                setQueue([...queue, queueNewSong.song])
            }
        }
    }, [queueNewSong])

    useEffect(() => {
        if (queueDeleteSong) {
            const queueAux = queue
            queueAux.splice(Number(queueDeleteSong) - 1, 1)

            setQueue(queueAux)
            setQueueDeleteSong(false)
        }
    }, [queueDeleteSong])

    useEffect(() => {
        if (playlistDeleteSong) {
            const playlistAux = JSON.parse(JSON.stringify(playlist))
            playlistAux.splice(Number(playlistDeleteSong) - 1, 1)

            setPlaylist(playlistAux)
            setPlaylistDeleteSong(false)
        }
    }, [playlistDeleteSong])

    useEffect(() => {
        if (playlistMoveSong) {
            const playlistAux = [...playlist]

            const fromData = playlistAux[playlistMoveSong.from]
            playlistAux.splice(playlistMoveSong.from, 1)
            playlistAux.splice(playlistMoveSong.to, 0, fromData)

            setPlaylist(playlistAux)
            setPlaylistMoveSong(undefined)
        }
    }, [playlistMoveSong])

    useEffect(() => {
        console.log('eventPlayPause', eventPlayPause)
        setIsPlaying(eventPlayPause)
        if (setActionPlay && setActionPause) {
            setActionPlay(undefined)
            setActionPause(false)
        }
    }, [eventPlayPause])

    useEffect(() => {
        if (setActionVolume && eventVolume) {
            setActionVolume(eventVolume)
        }
    }, [eventVolume])

    useEffect(() => {
        if (actionTimer && setActionTimer) {
            setActionTimer(undefined)
        } else {
            setTimer(eventTimer)
        }
    }, [eventTimer])

    const loadPlaylist = useCallback(async () => {
        const data = await getPlaylist()

        setPlaylist(data.result.playlist)
        setQueue(data.result.queue)
        setPlaying(data.result.songPlaying)
        setIsDisconnect(2)
    }, [])

    const loadClientId = useCallback(async () => {
        const id = await getClientId()
        setClientId(id)
    }, [])

    return isDisconnect === 3 ? <Disconnect /> : <></>
}

export default ServerSocket
