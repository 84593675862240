import { FC, useState } from 'react'
import moment from 'moment'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

interface Album {
    title: string
    image: string
    songs: number
    duration: number
    published: string
}
interface Data {
    index?: number
    title: string
    group: string
    year?: number
    reproductions: number
    duration: number
    album: Album
    tags: string[]
    request?: boolean
    className?: string
    isAdmin?: boolean
    isQueue?: boolean
    actionDeleteSong?: (index: number) => void
}

const ItemPlaylist: FC<Data> = ({
    index = -1,
    title,
    group,
    year,
    reproductions,
    duration,
    album,
    tags,
    request = false,
    className = '',
    isAdmin = false,
    isQueue = false,
    actionDeleteSong,
}) => {
    const [show, setShow] = useState(false)
    return (
        <>
            <div
                className={`${
                    isQueue ? 'border border-gray-400' : ''
                } bg-white rounded-md px-4 py-3 shadow-md h-min ${className}`}
            >
                <div className='flex justify-between items-center'>
                    <div className='flex items-center'>
                        <div>
                            <h3 className='font-bold'>{title}</h3>
                            <p>{group}</p>
                        </div>
                    </div>
                    <div className='flex items-center'>
                        {request ? (
                            <FontAwesomeIcon className='text-4xl mr-2' icon={faCheckCircle} />
                        ) : (
                            <></>
                        )}
                        {show ? (
                            <FontAwesomeIcon
                                className='text-3xl cursor-pointer'
                                icon={faAngleUp}
                                onClick={() => setShow(!show)}
                            />
                        ) : (
                            <FontAwesomeIcon
                                className='text-3xl cursor-pointer'
                                icon={faAngleDown}
                                onClick={() => setShow(!show)}
                            />
                        )}
                        {isAdmin ? (
                            <button
                                className='cursor-pointer text-sm pl-3 text-[#B2B5BE]'
                                onClick={
                                    actionDeleteSong ? () => actionDeleteSong(index) : undefined
                                }
                            >
                                <p className=''>x</p>
                            </button>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                {show ? (
                    <div className='transition'>
                        <div className='flex justify-between text-xs mt-2'>
                            {year ? (
                                <div className='text-center'>
                                    <p className='font-bold'>Año</p>
                                    <p>{year}</p>
                                </div>
                            ) : (
                                <></>
                            )}

                            <div className='text-center'>
                                <p className='font-bold'>Reproducciones</p>
                                <p>{new Intl.NumberFormat('en-US').format(reproductions)}</p>
                            </div>
                            <div className='text-center'>
                                <p className='font-bold'>Duración</p>
                                <p>{moment.utc(duration * 1000).format('mm:ss')}</p>
                            </div>
                        </div>
                        <div>
                            {/* {Object.keys(album).length > 0 ? (
                                <div className='flex text-[10px] mt-5'>
                                    <img
                                        className='mr-4'
                                        src={album.image}
                                        alt='albumItem'
                                        width='60'
                                    />
                                    <div>
                                        <p>{album.title}</p>
                                        <p>{album.published}</p> 
                                        <p>{album.songs} Canciones</p>
                                        <p>
                                            {album.duration * 1000 >= 3600000
                                                ? moment.utc(album.duration * 1000).format('hh') +
                                                  ' hr '
                                                : ''}
                                            {moment.utc(album.duration * 1000).format('mm')} min{' '}
                                            {moment.utc(album.duration * 1000).format('ss')} sec
                                        </p>
                                    </div>
                                </div>
                            ) : (
                                <></>
                            )} */}
                            <div className='flex flex-wrap justify-start gap-3 mt-5'>
                                {tags.map((tag, index) => (
                                    <div
                                        className='bg-black text-white w-fit px-3 rounded'
                                        key={`tagItemPlaylist${index}`}
                                    >
                                        {tag}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    )
}

export default ItemPlaylist
