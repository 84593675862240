import { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'

const MainLayout = () => {
    const [status, setStatus] = useState(navigator.onLine)
    const [keyGlobal, setKeyGlobal] = useState(0)

    const setOnline = () => setStatus(true)
    const setOffline = () => setStatus(false)

    useEffect(() => {
        window.addEventListener('online', setOnline)
        window.addEventListener('offline', setOffline)

        return () => {
            window.removeEventListener('online', setOnline)
            window.removeEventListener('offline', setOffline)
        }
    }, [])

    useEffect(() => {
        setKeyGlobal(keyGlobal + 1)
    }, [status])
    return (
        <>
            <div className='h-screen font-display bg-[#F0F0F0]'>
                <Outlet />
            </div>
        </>
    )
}

export default MainLayout
