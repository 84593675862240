import { FC, useState } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'

import { Song } from '../../services/playerApi'

import Modal from '../modal'
import Form from './form'

interface Data {
    addSongQueue: (song: Song) => void
    clientId: string
    isAdmin?: boolean
}
const AddSong: FC<Data> = ({ addSongQueue, clientId, isAdmin }) => {
    const [showModal, setShowModal] = useState(false)
    const [showModalError, setShowModalError] = useState(false)
    const [showModalSuccess, setShowModalSuccess] = useState(false)
    const [error, setError] = useState('')
    const [positionQueue, setPositionQueue] = useState<number>()

    return (
        <>
            <button
                className='absolute grid place-content-center shadow-lg right-5 bottom-[200px] bg-black w-12 h-12 rounded-full'
                onClick={() => setShowModal(true)}
            >
                <FontAwesomeIcon className='text-4xl text-white' icon={faPlus} />
            </button>
            <Modal
                show={showModal}
                setShow={setShowModal}
                content={
                    <Form
                        setShowModal={setShowModal}
                        setShowModalSuccess={setShowModalSuccess}
                        setShowModalError={setShowModalError}
                        addSongQueue={addSongQueue}
                        clientId={clientId}
                        setError={setError}
                        setPositionQueue={setPositionQueue}
                        isAdmin={isAdmin}
                        isMobile={true}
                    />
                }
            />
            <Modal
                show={showModalError}
                setShow={setShowModalError}
                content={
                    <div className='px-5 pb-4'>
                        <h1 className='text-center text-xl font-bold'>¡Fallo!</h1>
                        <p className='mt-4'>
                            Que mal, tu canción ha sido rechazada, ya que no cumple con las
                            condiciones.<br></br>
                            <br></br> ʺ{error}ʺ{' '}
                        </p>
                    </div>
                }
            />
            <Modal
                show={showModalSuccess}
                setShow={setShowModalSuccess}
                content={
                    <div className='px-5 pb-4'>
                        <h1 className='text-center text-xl font-bold'>¡Éxito!</h1>
                        <p className='mt-4'>
                            Que chimba, tu canción ha sido encolada, se reproducirá
                            {positionQueue === 0
                                ? ' a continuación.'
                                : ` después de las
                            siguientes ${positionQueue} canciones.`}
                        </p>
                    </div>
                }
            />
        </>
    )
}

export default AddSong
