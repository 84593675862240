import { FC, useState, Dispatch, SetStateAction } from 'react'
import { AxiosError } from 'axios'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons'

import { searchSong, queueSong, ResultSearch, Song } from '../../services/playerApi'
import { ErrorResponse } from '../../services/types'

import Spinner from '../spinner'
import Modal from '../modal'

interface Data {
    setShowModal: Dispatch<SetStateAction<boolean>>
    setShowModalSuccess: Dispatch<SetStateAction<boolean>>
    setShowModalError: Dispatch<SetStateAction<boolean>>
    setError: Dispatch<SetStateAction<string>>
    setPositionQueue: Dispatch<SetStateAction<number | undefined>>
    addSongQueue: (song: Song, priority?: boolean) => void
    clientId: string
    isAdmin?: boolean
    isMobile?: boolean
}
const Form: FC<Data> = ({
    setShowModal,
    setShowModalSuccess,
    setShowModalError,
    setError,
    addSongQueue,
    setPositionQueue,
    clientId,
    isAdmin = false,
    isMobile = false,
}) => {
    const [showModalInfo, setShowModalInfo] = useState(false)
    const [lookingFor, setLookingFor] = useState(false)
    const [btnSearchDisabled, setBtnSearchDisabled] = useState(false)
    const [search, setSearch] = useState('')
    const [songSelectId, setSongSelectId] = useState(0)
    const [submit, setSubmit] = useState(false)
    const [resultSearch, setResultSearch] = useState<ResultSearch[]>([])
    const [priority, setPriority] = useState(false)

    const handleSearch = async () => {
        setSongSelectId(0)
        setLookingFor(true)
        setBtnSearchDisabled(true)
        if (search !== '') {
            const data = await searchSong(search, isAdmin)
            setLookingFor(false)
            setBtnSearchDisabled(false)
            setResultSearch(data.result)
        }
    }

    const handleSubmit = async () => {
        setSubmit(true)
        setBtnSearchDisabled(true)

        try {
            const { videoId } = resultSearch[songSelectId - 1]
            const result = await queueSong(videoId, clientId, priority, isAdmin)

            setSubmit(false)
            setBtnSearchDisabled(false)
            setLookingFor(false)
            setSongSelectId(0)
            setSearch('')
            setResultSearch([])

            addSongQueue(result.result, priority)
            setPositionQueue(result.result.position)
            setShowModalSuccess(true)
            setShowModal(false)
        } catch (err) {
            const error = err as AxiosError<ErrorResponse>
            console.log(err)
            setSubmit(false)
            setBtnSearchDisabled(false)
            setLookingFor(false)
            setShowModalError(true)
            setError(error.response?.data.message ?? '')
        }
    }

    return (
        <>
            <Modal
                show={showModalInfo}
                setShow={setShowModalInfo}
                content={
                    <div className='px-5 pb-4'>
                        <h1 className='text-center text-xl font-bold'>¡Información!</h1>
                        <p className='mt-4'>
                            La canción solicitada debe ser del genero rock o sus derivados, también
                            la canción no debe haberse reproducido en las últimas 6 canciones.
                            <br></br>
                            <br></br>
                            Una vez aceptada la canción será puesta en la fila de canciones
                            solicitadas la cual tiene prioridad en la lista general.
                            <br></br>
                            <br></br>
                            Nota: No puedes pedir mas de 3 canciones al mismo tiempo.
                        </p>
                    </div>
                }
            />
            {isAdmin ? (
                <></>
            ) : (
                <div className='relative'>
                    <FontAwesomeIcon
                        className='absolute top-[-25px] left-0 text-2xl mr-2'
                        icon={faInfoCircle}
                        onClick={() => setShowModalInfo(true)}
                    />
                </div>
            )}
            <div className='text-center' style={{ height: 'calc(100% - 84px)' }}>
                {isAdmin ? <></> : <h1 className='font-bold text-xl'>Pide una canción</h1>}
                <div className='mt-3 flex justify-center'>
                    <div className='text-center block'>
                        <input
                            className='p-2 h-10 border border-[#C1B5B5] rounded text-black placeholder:text-[#C1B5B5] placeholder:text-xs'
                            type='text'
                            name='search'
                            id='search'
                            placeholder='Canción / Artista'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            disabled={btnSearchDisabled}
                            onKeyUp={(e) => (e.key === 'Enter' ? handleSearch() : null)}
                        />
                    </div>
                    <button
                        className='bg-black text-white p-2 rounded ml-2 disabled:bg-[#E5E5E5] disabled:text-[#ABABAB]'
                        onClick={() => handleSearch()}
                        disabled={btnSearchDisabled}
                    >
                        <FontAwesomeIcon icon={faSearch} />
                    </button>
                </div>
                {/* <p className='text-xs'>Escribe la palabra sin errores</p> */}
                <div
                    className='mt-5 grid grid-cols-1 place-content-start gap-4 m-3 overflow-y-auto'
                    style={{ height: `${isMobile ? 'calc(100vh - 400px)' : 'calc(100% - 104px)'}` }}
                >
                    {lookingFor ? (
                        <div>
                            <Spinner className='w-8 h-8 mx-auto' />
                            <p className='mt-2'>Buscando ...</p>
                        </div>
                    ) : resultSearch.length === 0 ? (
                        <p>Sin resultados</p>
                    ) : (
                        <>
                            {resultSearch.map((result, index) => (
                                <button
                                    className={`flex justify-between h-min ${
                                        songSelectId === index + 1 ? 'bg-[#C3C3C3]' : ''
                                    }`}
                                    key={`itemResultSearch${index}`}
                                    onClick={() =>
                                        setSongSelectId(songSelectId === index + 1 ? 0 : index + 1)
                                    }
                                >
                                    <div className='relative w-[60px] h-[60px] grid content-center mr-4'>
                                        <img
                                            className='max-w-none'
                                            width={60}
                                            src={result.image}
                                            alt=''
                                        />
                                    </div>
                                    <div
                                        className='leading-tight text-left'
                                        style={{ width: 'calc(100% - 76px)' }}
                                    >
                                        <p className='font-bold'>{result.name}</p>
                                        <p className='whitespace-nowrap text-ellipsis overflow-hidden'>
                                            {result.artist}
                                        </p>
                                    </div>
                                    <p className='float-right pl-3'>
                                        {moment.utc(result.duration * 1000).format('mm:ss')}
                                    </p>
                                </button>
                            ))}
                        </>
                    )}
                </div>
                {isAdmin ? (
                    <div>
                        <input type='checkbox' onClick={() => setPriority(!priority)} /> ¿Reproducir
                        a continuacion?
                    </div>
                ) : (
                    <></>
                )}
                <button
                    className='flex justify-center mx-auto mt-3 mb-3 w-4/5 bg-black text-white p-0.5 rounded disabled:bg-[#E5E5E5] disabled:text-[#ABABAB]'
                    disabled={songSelectId === 0 || submit}
                    onClick={() => handleSubmit()}
                >
                    {submit ? <Spinner className='w-5 h-5 mr-1' /> : <></>}
                    {isAdmin ? 'En listar' : 'Pedir'}
                </button>
            </div>
        </>
    )
}

export default Form
