import Axios from 'axios'
import { SuccessResponse } from './types'

const { REACT_APP_DEV_ENDPOINT_API_PLAYER } = process.env

export interface Song {
    title: string
    group: string
    year?: number
    reproductions: number
    duration: number
    url: string
    image: string
    album?: string
    tags: string
    clientId?: string
    position?: number
}

export interface ResultGetPlaylist {
    playlist: Song[]
    queue: Song[]
    songPlaying: Song
}

export const getPlaylist = async () => {
    const { data } = await Axios.get<SuccessResponse<ResultGetPlaylist>>(
        `${REACT_APP_DEV_ENDPOINT_API_PLAYER}/playlist`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )

    return data
}

export interface ResultSearch {
    videoId: string
    name: string
    artist: string
    image: string
    duration: number
}

export const searchSong = async (word: string, isAdmin = false) => {
    const { data } = await Axios.get<SuccessResponse<ResultSearch[]>>(
        `${REACT_APP_DEV_ENDPOINT_API_PLAYER}/search?word=${word}&isAdmin=${isAdmin}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )

    return data
}

export const queueSong = async (
    videoId: string,
    clientId?: string,
    priority = false,
    isAdmin = false,
) => {
    const { data } = await Axios.post<SuccessResponse<Song>>(
        `${REACT_APP_DEV_ENDPOINT_API_PLAYER}/queue-song`,
        {
            videoId,
            clientId,
            priority,
            isAdmin,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )

    return data
}

export const deleteSongPlaylist = async (index: number) => {
    const { data } = await Axios.get<SuccessResponse<boolean>>(
        `${REACT_APP_DEV_ENDPOINT_API_PLAYER}/delete-song-playlist/${index}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )

    return data
}

export const deleteSongQueue = async (index: number) => {
    const { data } = await Axios.get<SuccessResponse<boolean>>(
        `${REACT_APP_DEV_ENDPOINT_API_PLAYER}/delete-song-queue/${index}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )

    return data
}

export const moveSongPlaylist = async (from: number, to: number) => {
    const { data } = await Axios.get<SuccessResponse<boolean>>(
        `${REACT_APP_DEV_ENDPOINT_API_PLAYER}/move-song-playlist/${from}/${to}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )

    return data
}

interface ResponseValidateSession {
    result: boolean
    message: string
}
export const validateSession = async (tableId: string, clientId: string) => {
    const { data } = await Axios.post<SuccessResponse<ResponseValidateSession>>(
        `${REACT_APP_DEV_ENDPOINT_API_PLAYER}/validate-session`,
        {
            tableId,
            clientId,
        },
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )

    return data.result
}

export const emptyTable = async (id: string) => {
    const { status } = await Axios.delete(
        `${REACT_APP_DEV_ENDPOINT_API_PLAYER}/empty-table/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
            },
        },
    )

    return status === 201
}
