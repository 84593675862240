import { FC } from 'react'
import Spinner from '../spinner'

const Disconnect: FC = () => {
    return (
        <div className='absolute w-full h-full top-0 bg-black/50 z-10 content-center'>
            <Spinner className='w-8 h-8 mx-auto'></Spinner>
            <p className='text-white text-center'>Reanudando conexión ...</p>
        </div>
    )
}

export default Disconnect
