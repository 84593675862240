import { FC, useCallback, useEffect, useState, DragEvent, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

import {
    Song,
    deleteSongPlaylist,
    deleteSongQueue,
    moveSongPlaylist,
    validateSession,
    emptyTable,
} from '../../../../services/playerApi'

import ItemPlaylist from '../../../../components/itemPlaylist'
import Player from '../../../../components/player'
import AddSong from '../../../../components/addSong'

import ServerSocket from '../../../../socket'
import Spinner from '../../../../components/spinner'
import Login from '../../admin/player/login'

interface Data {
    isAdmin?: boolean
}
const Music: FC<Data> = ({ isAdmin }) => {
    const [isLoginSuccessful, setIsLoginSuccessful] = useState(false)
    const [searchParams] = useSearchParams()
    const [isClientValid, setIsClientValid] = useState(false)
    const [messageClientValid, setMessageClientValid] = useState('')
    const [isReady, setIsReady] = useState(false)
    const [nextSong, setNextSong] = useState(false)
    const [timer, setTimer] = useState(0)
    const [queue, setQueue] = useState<Song[]>([])
    const [playlist, setPlaylist] = useState<Song[]>([])
    const [playing, setPlaying] = useState<Song | undefined>(undefined)
    const [isPlaying, setIsPlaying] = useState(false)
    const [clientId, setClientId] = useState('')
    const [actionVolume, setActionVolume] = useState<number | undefined>(undefined)
    const [actionTimer, setActionTimer] = useState<number | undefined>()
    const [actionPlay, setActionPlay] = useState<boolean | undefined>(false)
    const [actionPause, setActionPause] = useState<boolean | undefined>(false)
    const [actionNext, setActionNext] = useState(false)
    const [updateQueue, setUpdateQueue] = useState<{ song: Song; priority: boolean }>()
    const [actionDeletePlaylist, setActionDeletePlaylist] = useState<boolean | number>(false)
    const [actionDeleteQueue, setActionDeleteQueue] = useState<boolean | number>(false)
    const [actionMovePlaylist, setActionMovePlaylist] = useState<
        { from: number; to: number } | undefined
    >()

    useEffect(() => {
        if (isAdmin) {
            const isLogin = localStorage.getItem('successLogin')
            if (isLogin) {
                setIsLoginSuccessful(true)
            }
        }
    }, [])

    useEffect(() => {
        const tableId = searchParams.get('table')
        if (isReady && clientId !== '' && tableId) {
            validateClient(tableId, clientId)
        }
    }, [isReady, clientId])

    const validateClient = useCallback(async (tableId: string, clientId: string) => {
        const result = await validateSession(tableId, clientId)

        setIsClientValid(result.result)
        setMessageClientValid(result.message)
    }, [])

    useEffect(() => {
        if (nextSong) {
            if (queue.length > 0) {
                const newPlaying = JSON.parse(JSON.stringify(queue[0]))
                setPlaying(newPlaying)

                const newQueue = JSON.parse(JSON.stringify(queue))
                newQueue.shift()
                setQueue(newQueue)
            } else {
                const newPlaying = JSON.parse(JSON.stringify(playlist[0]))
                setPlaying(newPlaying)

                const newPlaylist = JSON.parse(JSON.stringify(playlist))
                newPlaylist.shift()
                setPlaylist([...newPlaylist, newPlaying])
            }
            setNextSong(false)
            setActionNext(false)
            setActionPlay(true)
            setActionPause(false)
        }
    }, [nextSong])

    const actionQueue = (song: Song) => {
        setQueue([...queue, song])
        setUpdateQueue({ song, priority: false })
    }

    const actionDeleteSongPlaylist = async (index: number) => {
        const { result } = await deleteSongPlaylist(index + 1)
        if (result) {
            const playlistAux = playlist
            playlistAux.splice(index, 1)

            setPlaylist(playlistAux)
            setActionDeletePlaylist(index + 1)
        }
    }

    const actionDeleteSongQueue = async (index: number) => {
        const { result } = await deleteSongQueue(index + 1)
        if (result) {
            const queueAux = queue
            queueAux.splice(index, 1)

            setQueue(queueAux)
            setActionDeleteQueue(index + 1)
        }
    }

    const close = async () => {
        const tableId = searchParams.get('table')
        if (tableId) {
            const result = await emptyTable(tableId)
            if (result) {
                setIsClientValid(false)
                setMessageClientValid(
                    'La mesa fue liberada, entre en el nuevo dispositivo o recargue la pagina.',
                )
            }
        }
    }

    const dragItem = useRef<number | null>(null)
    const dragOverItem = useRef<number | null>(null)
    const dragStart = (e: DragEvent<HTMLDivElement>, position: number) => {
        dragItem.current = position
    }
    const dragEnter = (e: DragEvent<HTMLDivElement>, position: number) => {
        dragOverItem.current = position
    }
    const drop = async () => {
        if (dragItem.current !== null && dragOverItem.current !== null) {
            const copyListItems = [...playlist]
            const dragItemContent = copyListItems[dragItem.current]
            copyListItems.splice(dragItem.current, 1)
            copyListItems.splice(dragOverItem.current, 0, dragItemContent)

            const [from, to] = [dragItem.current, dragOverItem.current]
            dragItem.current = null
            dragOverItem.current = null

            setPlaylist(copyListItems)
            await moveSongPlaylist(from, to)
            setActionMovePlaylist({ from, to })
        }
    }

    return (
        <>
            <ServerSocket
                playlist={playlist}
                queue={queue}
                isPlaying={isPlaying}
                isReady={isReady}
                updateQueue={updateQueue}
                actionMovePlaylist={actionMovePlaylist}
                actionDeletePlaylist={actionDeletePlaylist}
                actionDeleteQueue={actionDeleteQueue}
                actionVolume={actionVolume}
                actionPlay={actionPlay}
                actionPause={actionPause}
                actionNext={actionNext}
                actionTimer={actionTimer}
                setClientId={setClientId}
                setIsPlaying={setIsPlaying}
                setIsReady={setIsReady}
                setNextSong={setNextSong}
                setPlaying={setPlaying}
                setPlaylist={setPlaylist}
                setQueue={setQueue}
                setTimer={setTimer}
                setActionMovePlaylist={setActionMovePlaylist}
                setActionDeletePlaylist={setActionDeletePlaylist}
                setActionDeleteQueue={setActionDeleteQueue}
                setActionVolume={setActionVolume}
                setActionPlay={setActionPlay}
                setActionPause={setActionPause}
                setActionTimer={setActionTimer}
            />
            {isReady && (isClientValid || isAdmin) ? (
                (isAdmin && isLoginSuccessful) || !isAdmin ? (
                    playlist.length > 0 ? (
                        <>
                            <div className='mt-3' style={{ height: 'calc(100% - 25px)' }}>
                                {!isAdmin ? (
                                    <FontAwesomeIcon
                                        className='absolute top-5 right-8 text-2xl cursor-pointer'
                                        icon={faXmark}
                                        onClick={close}
                                    />
                                ) : (
                                    <></>
                                )}
                                <div
                                    className='flex flex-col gap-4 m-3 overflow-y-auto scroll-smooth'
                                    style={{ height: 'calc(100% - 90px)' }}
                                >
                                    {queue.length > 0 ? (
                                        <>
                                            <h1 className='text-center text-black'>
                                                LISTA DE PEDIDOS
                                            </h1>
                                            {queue.map((song, index) => (
                                                <ItemPlaylist
                                                    index={index}
                                                    title={song.title}
                                                    group={song.group}
                                                    year={song.year}
                                                    duration={song.duration}
                                                    reproductions={song.reproductions}
                                                    album={JSON.parse(song.album ?? '{}')}
                                                    key={`ItemPlaylist${index}`}
                                                    tags={JSON.parse(song.tags ?? '{}')}
                                                    request={
                                                        song.clientId
                                                            ? song.clientId === clientId
                                                            : false
                                                    }
                                                    className='basis-full'
                                                    isAdmin={isAdmin}
                                                    isQueue={true}
                                                    actionDeleteSong={actionDeleteSongQueue}
                                                />
                                            ))}
                                            <hr className='bg-black h-1.5 w-full' />
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                    <h1 className='text-center text-black'>
                                        LISTA DE REPRODUCCIÓN
                                    </h1>
                                    {playlist.map((song, index) => (
                                        <div
                                            className='cursor-move'
                                            onDragStart={(e) => dragStart(e, index)}
                                            onDragEnter={(e) => dragEnter(e, index)}
                                            onDragOver={(e) =>
                                                e.currentTarget.classList.add(
                                                    'listItemDraggableOver',
                                                )
                                            }
                                            onDragLeave={(e) =>
                                                e.currentTarget.classList.remove(
                                                    'listItemDraggableOver',
                                                )
                                            }
                                            onDragEnd={drop}
                                            key={index}
                                            draggable={isAdmin}
                                        >
                                            <ItemPlaylist
                                                index={index}
                                                title={song.title}
                                                group={song.group}
                                                year={song.year}
                                                duration={song.duration}
                                                reproductions={song.reproductions}
                                                album={JSON.parse(song.album ?? '{}')}
                                                key={`ItemPlaylist${index}`}
                                                tags={JSON.parse(song.tags ?? '{}')}
                                                className='basis-full'
                                                isAdmin={isAdmin}
                                                actionDeleteSong={actionDeleteSongPlaylist}
                                            />
                                        </div>
                                    ))}
                                </div>
                                <AddSong
                                    addSongQueue={actionQueue}
                                    clientId={clientId}
                                    isAdmin={isAdmin}
                                />
                                <Player
                                    image={playing ? playing.image : ''}
                                    title={playing ? playing.title : ''}
                                    group={playing ? playing.group : ''}
                                    duration={playing && playing.duration ? playing.duration : 0}
                                    timer={timer}
                                    volume={isAdmin ? actionVolume : undefined}
                                    actionTimer={isAdmin ? actionTimer : undefined}
                                    isPlaying={isPlaying}
                                    setActionVolume={isAdmin ? setActionVolume : undefined}
                                    setActionPlay={isAdmin ? setActionPlay : undefined}
                                    setActionPause={isAdmin ? setActionPause : undefined}
                                    setActionNext={isAdmin ? setActionNext : undefined}
                                    setActionTimer={isAdmin ? setActionTimer : undefined}
                                    isAdmin={isAdmin}
                                    request={
                                        playing && playing.clientId
                                            ? playing.clientId === clientId
                                            : false
                                    }
                                />
                            </div>
                        </>
                    ) : (
                        <div
                            style={{
                                textAlign: 'center',
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}
                        >
                            <Spinner className='w-8 h-8 mx-auto' />
                            Cargando ...
                        </div>
                    )
                ) : (
                    <Login setIsLoginSuccessful={setIsLoginSuccessful} />
                )
            ) : (
                <div className='grid h-full content-center'>
                    <h1 className='text-center'>
                        {messageClientValid === ''
                            ? 'El reproductor no esta disponible.'
                            : messageClientValid}
                    </h1>
                </div>
            )}
        </>
    )
}

export default Music
